<template>
 <div
  class="text-center items-center py-1 text-white bg-pale-sky rounded-lg shadow-md">
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatistics'">
   {{ $t("expertStatisticsNavigation.origins_title") }} {{urlType === 'queue' ? 'PAR FILE' : 'PAR UTILISATEUR'}}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsAnsweredDaily'">
   {{ $t("expertStatisticsNavigation.answeredUnansweredCallsDaily") }} {{urlType === 'queue' ? 'PAR FILE' : 'PAR UTILISATEUR'}}*
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsAnsweredUnanswered'">
   {{ $t("expertStatisticsNavigation.answeredUnansweredCalls") }} {{urlType === 'queue' ? 'PAR FILE' : 'PAR UTILISATEUR'}}*
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsAnsweredUnansweredWeekYear'">
   {{ $t("expertStatisticsNavigation.answeredUnansweredCalls") }} -
   <span class="text-lg italic">
    {{ $t("answeredUnansweredCallsWeekYear") }}
   </span>
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsAnsweredUnansweredWeekDay'">
   {{ $t("expertStatisticsNavigation.answeredUnansweredCalls") }} -
   <span class="text-lg italic">
    {{ $t("answeredUnansweredCallsWeekDay") }}
   </span>
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOriginDetail'">
   {{ $t("expertStatisticsNavigation.originsTop10") }} {{urlType === 'queue' ? 'PAR FILE' : 'PAR UTILISATEUR'}}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsReportList'">
   {{ $t("expertStatisticsNavigation.reportList") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsCallerNumbers'">
   {{ $t("expertStatisticsNavigation.callerNumbers") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsDIDReport'">
   Rapport {{ $t("didReport") }} 
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsThirdPartyNumber'">
   {{ $t("expertStatisticsNavigation.thirdPartyNumber") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOutbound'">
   {{ $t("expertStatisticsNavigation.overview") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOutboundAnswered'">
   {{ $t("expertStatisticsNavigation.answeredUnansweredCalls") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOutboundDuration'">
   {{ $t("expertStatisticsNavigation.durations") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOutboundPbx'">
   {{ $t("expertStatisticsNavigation.pbx") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsOutboundTrunk'">
   {{ $t("expertStatisticsNavigation.trunk") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold mb-"
   v-if="this.$route.name === 'ExpertStatisticsFullQueueReport'">
   Rapport {{ $t("queueDetail") }}
   <span class="text-xl text-gray-800" v-if="selectedQueue">
    -
    <span v-for="(queue, idx) in selectedQueue" :key="queue">
     {{ queue
     }}<span v-show="idx < selectedQueue.length - 1" class="mr-1">,</span>
    </span>
    <!-- <span v-if="pbxMapData">{{
                pbxMapData.call_queues[selectedQueue].name
              }}</span> -->
   </span>
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsQueueComparator'">
   {{ $t("queueComparison") }}
   <span v-if="compareElement && compareElement.length > 0">
    - {{ compareElement[1] }} {{ $t("compareTo") }}
    {{ compareElement[0] }}
    <!-- <span v-if="pbxMapData">{{
                pbxMapData.call_queues[selectedQueue].name
              }}</span> -->
   </span>
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="this.$route.name === 'ExpertStatisticsTRTC'">
   {{ $t("didCallers") }}
   <!-- <span v-if="selectedQueue">
              - {{ selectedQueue }}
              
            </span> -->
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="
    this.$route.name === 'ExpertStatisticsGroups' &&
    this.$route.path.includes('did')
   ">
   {{ $t("groupsNumbers") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="
    this.$route.name === 'ExpertStatisticsGroups' &&
    this.$route.path.includes('queue')
   ">
   {{ $t("groupsQueues") }}
  </h2>
  <h2
   class="uppercase text-lg font-bold"
   v-if="
    this.$route.name === 'ExpertStatisticsGroups' &&
    this.$route.path.includes('extension')
   ">
   {{ $t("groupsExtensions") }}
  </h2>
 </div>
 <!-- <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
  <div
   v-if="hostNames"
   class="mt-2 flex items-center text-sm text-gray-600 font-medium">
   <font-awesome-icon
    icon="server"
    class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-600" />
   <select
    class="rounded border-transparent text-sm -ml-1.5"
    v-model="selectHostName">
    <option v-for="host in hostNames" :key="host" :value="host">
     {{ host }}
    </option>
   </select>
  </div>

  <div v-else class="mt-2 flex items-center text-sm text-gray-600 font-medium">
   <font-awesome-icon
    icon="server"
    class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-600" />
   <select class="rounded border-transparent text-sm -ml-1.5">
    <option>
     {{ $t("noHostName") }}
    </option>
   </select>
  </div>


  <div
   v-if="this.periodSelector"
   class="mt-2 flex items-center text-sm text-gray-500">
   <font-awesome-icon
    :icon="
     this.buttonSelected === $t('dashboards.day')
      ? 'calendar-day'
      : this.buttonSelected === 'week'
      ? 'calendar-week'
      : this.buttonSelected === $t('dashboards.month')
      ? 'calendar'
      : this.buttonSelected === 'custom'
      ? 'calendar-days'
      : 'calendar'
    "
    class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-500" />
   {{ parseDateRange(this.periodSelector) }}
  </div>

  <div
   class="mt-2 flex items-center text-sm text-gray-500"
   v-if="pbxMenuType === 'statistics'">
   <font-awesome-icon
    icon="clock"
    class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-500" />
   {{
    this.timeSelected.length > 0
     ? parseTimeRange(this.timeSelected)
     : $t("dashboards.fullDay")
   }}
  </div>
  <div
   class="mt-2 flex items-center text-sm text-gray-500"
   v-if="pbxMenuType === 'statistics'">
   <font-awesome-icon
    icon="phone"
    class="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-500" />
   {{
    urlType === "extension"
     ? "Extension"
     : $t("sidebar.specialNavigation.callqueue")
   }}
  </div>
  <div
   class="mt-2 flex items-center text-sm text-gray-500"
   v-if="groupSelected && groupSelectedName.length > 0">
   <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-5 w-5"
    viewBox="0 0 20 20"
    fill="currentColor">
    <path
     d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
   </svg>
   <ul>
    <li class="inline" v-for="(name, idx) in groupSelectedName" :key="name">
     <span class="ml-1">{{ name }}</span>
     <span v-show="idx < groupSelectedName.length - 1">,</span>
    </li>
   </ul>
  </div>
 </div> -->
 
 <div class="z-10 mt-4" :key="this.closeForm" v-if="pbxMenuType === 'groups'">
  <button
   @click.prevent="this.$emit('openForm')"
   class="rounded group body__header--button text-white sm:text-sm lg:text-sm xl:text-sm md:text-sm inline-flex items-center text-sm font-medium shadow p-2 mr-0">
   <span class="">{{ this.buttonTitle }}</span>
  </button>
 </div>


 <popup-share-report-queue-detail
  ref="popupShareReportQueueDetail"
  :timeOption="this.buttonSelected"
  :time="this.timeSelected"
  :elementOption="this.urlType"
  :selections="this.selections"
  :parseDateRange="parseDateRange"
  :parseTimeRange="parseTimeRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.selectHostName"
  :selectHostContext="this.selectHostContext"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)" />
 <popup-share-report-queue-detail
  ref="popupShareScheduleReportQueueDetail"
  :timeOption="this.buttonSelected"
  :time="this.timeSelected"
  :elementOption="this.urlType"
  :selections="this.selections"
  :parseDateRange="parseDateRange"
  :parseTimeRange="parseTimeRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.selectHostName"
  :selectHostContext="this.selectHostContext"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)" />
 <PopupShareReport
  ref="popupShareReport"
  :timeOption="this.buttonSelected"
  :time="this.timeSelected"
  :elementOption="this.urlType"
  :selections="this.selections"
  :parseDateRange="parseDateRange"
  :parseTimeRange="parseTimeRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.selectHostName"
  :selectHostContext="this.selectHostContext"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)" />
 <PopupShareReport
  ref="popupShareScheduleReport"
  :timeOption="this.buttonSelected"
  :time="this.timeSelected"
  :elementOption="this.urlType"
  :selections="this.selections"
  :parseDateRange="parseDateRange"
  :parseTimeRange="parseTimeRange"
  :periodSelector="this.periodSelector"
  :selectHostName="this.selectHostName"
  :selectHostContext="this.selectHostContext"
  @activeBanner="$emit('activeBanner')"
  @successMsg="$emit('successMsg', $event)"
  @success="$emit('success', $event)" />
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage" />
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { mapGetters } from "vuex";
import QuickSearch from "../QuickSearch.vue";
import PopupShareReport from "./PopupShareReport.vue";
import PopupShareReportQueueDetail from "./PopupShareReportQueueDetail.vue";
import { ChevronDownIcon } from "@heroicons/vue/solid";
import SearchFilter from "../SearchFilter.vue";

const account = localStorage.getItem("account");

const hostContext = [
 { name: "callQueue", id: 0, value: "queue" },
 { name: "extension", id: 1, value: "extension" },
];

const reports = ["report 1", "report 2", "report 3"];

export default {
 props: [
  "selectHostname",
  "parseDateRange",
  "buttonSelected",
  "parseTimeRange",
  "periodSelector",
  "timeSelected",
  "urlType",
  "pbxMenuType",
  "elementToRemove",
  "elements",
  "buttonTitle",
  "selectedQueue",
  "compareElement",
 ],
 components: {
  QuickSearch,
  PopupShareReport,
  PopupShareReportQueueDetail,
  ChevronDownIcon,
  SearchFilter,
  Loading,
 },
 data() {
  return {
   isLoading: false,
   reports,
   hostContext,
   selectReport: this.$t("reportList"),
   search: "",
   closeForm: false,
   account,
   enabled: false,
   selectHostName: null,
   selectHostContext: "",
   clearAllElements: false,
   elementListConcatReduce: [],
   elementGroupsExtensionList: [],
   elementGroupsQueueList: [],
   extensionsList: [],
   queueList: [],
   clearAllElements: true,
   selectAllElements: false,
   selectedAllElements: false,
   pbxMapData: {},
   selections: "",
  };
 },
 methods: {
  setHostTimeZone(zone) {
   let date = new Date(`August 19, 1975 12:00:00 ${zone}`);
   let utcHourOffset = 12 - date.getUTCHours();
   localStorage.setItem("hostTimeZone", utcHourOffset + 1);
  },
  getTimeZoneByHost(host) {
   let timeZones = {
    "encomconseil.3cx.fr": "GMT+02:00",
    "demo-encom.3cx.fr": "GMT+02:00",
    "plus-voyages.3cx.fr": "GMT+02:00",
    "tdaconseil.my3cx.fr": "GMT+02:00",
   };
   if (host) {
    if (timeZones[host]) {
     return timeZones[host];
    } else {
     return "GMT+02:00";
    }
   } else {
    return "GMT+02:00";
   }
  },
 },
 mounted() {
  this.selectHostContext = this.$store.state.hostContext;
  this.selectHostName = this.hostName;
 },
 computed: {
  ...mapGetters([
   "buttonSelector",
   "hostName",
   "hostNames",
   "groupSelected",
   "groupSelectedName",
  ]),
 },
 watch: {
  search: function (val) {
   this.$emit("update:search", val);
  },
  selectHostName: function (val) {
   this.$emit("select-hostname", val);
   this.$store.dispatch("hostName", val);
   localStorage.setItem("hostName", val);
   this.setHostTimeZone(this.getTimeZoneByHost(val));
   // this.concatArray();
  },
  selectHostContext: function (val) {
   localStorage.setItem("hostContext", val);
   this.$store.dispatch("hostContext", val);
   this.$emit("select-hostContext", val);
  },
 },
};
</script>

<style scoped>
.route_table_header {
 padding: 10px 40px 10px 40px;
}
.select {
 padding-right: 0.5rem;
}
</style>
